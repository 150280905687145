import React from "react";

export interface OrgInfo {
  orgId?: string;
  companyLogoUrl?: string;
  otpInstructions?: string;
  name?: string;
  redirectUrl?: string;
}

export const LOCAL_STORAGE_ORG_INFO = "intuitBankFeedRutterLinkOrgInfo";

type IntuitBankFeedsCommonLayoutProps = {
  companyLogoUrl?: string;
  name?: string;
  errorMessage?: string;
  children: React.ReactNode;
};

const IntuitBankFeedsCommonLayout: React.FC<
  IntuitBankFeedsCommonLayoutProps
> = ({ companyLogoUrl, name, errorMessage, children }) => {
  return (
    <div className="fixed inset-0 z-50 flex flex-col justify-center items-center p-5 box-border">
      <div className="w-full max-w-lg">
        {companyLogoUrl && (
          <div className="flex items-center">
            <div className="mr-4">
              <img
                src={companyLogoUrl}
                alt="logo"
                className="w-15 h-15 max-w-[60px] max-h-[60px]"
              />
            </div>
          </div>
        )}
        {name && <h2 className="font-semibold text-xl">{name}</h2>}
        <h1 className="mt-4 font-semibold text-2xl">Connect your bank</h1>
        <p className="mt-1 font-thin text-red-500">{errorMessage}</p>
        {children}
      </div>
    </div>
  );
};

export default IntuitBankFeedsCommonLayout;
