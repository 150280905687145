import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Input, Steps } from "antd";
import Link from "antd/lib/typography/Link";
import axios from "axios";
import qs from "qs";
import { useCallback, useState } from "react";
import { JARIS_ORG_ID, UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { getBackendUrl, STORES } from "../utils";
import PlatformPageText from "./shared/PlatformPageText";
import TextUnderConnect, {
  shouldShowTextUnderConnect,
} from "./shared/TextUnderConnect";
import Arcade from "../components/Arcade";

const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

// todo(marko): add sandbox support for Sage Intacct
const SageIntacct = (props: Props) => {
  const { onComplete } = props;

  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const store = Store.useStore();
  const autoPlatform = store.get("autoPlatform");
  const isSandbox = store.get("isSandbox");
  const organization = store.get("organization");
  const orgId = store.get("orgId");

  const itemId = store.get("itemId");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [senderIdLinkClicked, setSenderIdLinkClicked] = useState(false);

  const handleAuthError = useCallback((e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  }, []);

  // TODO - handle fake completion for sandbox
  function handleFakeComplete() {}

  const handleComplete = async () => {
    setIsLoading(true);

    const query = qs.stringify({
      linkupdate: itemId ? itemId : undefined,
    });

    persistLinkState(STORES.SAGE_INTACCT, store.getState());

    // logic to pass to routes.ts appUrlHandler in rutter-backend
    axios
      .post(`${getBackendUrl()}/sageintacct/verify-credential?${query}`, {
        username,
        password,
        companyId,
        organizationId: orgId,
      })
      .then((response) => {
        const { data } = response;
        const { public_token } = data;
        onComplete(public_token);
      })
      .catch(handleAuthError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const senderIdLink =
    orgId === JARIS_ORG_ID
      ? "https://rutterapi.notion.site/Sage-Intacct-Authorize-Jaris-Sender-ID-3f4e474065694f77bc406aa7dc814435?pvs=4"
      : "https://rutterapi.notion.site/Sage-Intacct-Authorize-Rutter-Sender-ID-5ceb2115764d4f11bd6d9124ffe656a5";

  return (
    <div style={{ padding: "1.6rem", maxWidth: 800, minHeight: "100vh" }}>
      <div style={{ display: "flex" }} className="">
        {!autoPlatform && (
          <Button
            onClick={props.onBack}
            icon={<ArrowLeftOutlined />}
            className="flex items-center"
          >
            Back
          </Button>
        )}
      </div>
      <div
        className="flex items-center flex-column mt-4"
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <img
          style={{ height: "80px" }}
          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/sage-intacct.png"
          alt=""
        />
      </div>

      {organization?.platformPageText && (
        <div className="mb-2 text-xs">
          <PlatformPageText></PlatformPageText>
        </div>
      )}
      {
        // TODO: eric 1/19/2023 Build better steps in frontend when i remember how to use react
      }
      <Steps direction="vertical" className="mt-4">
        <Step
          title="Authorize Sender ID in your Sage Intacct Platform Account."
          status="process"
          description={
            <>
              <div className="font-semibold mt-2">A: Enable Web Services.</div>
              <Arcade
                src="https://demo.arcade.software/lqEAZu4HUatfMsoHR59f?embed&show_copy_link=true"
                title="Sage Intacct - Enable Web Services"
              />
              <div className="font-semibold mt-2">
                B: Add the Rutter Sender ID
              </div>
              <Arcade
                src="https://demo.arcade.software/ktjCfgp9lvdb53ZlRTL5?embed&show_copy_link=true"
                title="Sage Intacct - Add the Rutter Sender ID"
              />
            </>
          }
        />
        <Step
          title="Input User Credentials"
          status="process"
          description={
            <>
              <div className="text-sm">
                Please enter your Sage Intacct Company ID, username, and
                password. Please make sure that this account has full
                permissions to all objects.
                <Link
                  target="_blank"
                  href="https://rutterapi.notion.site/Sage-Intacct-Create-Web-Service-User-54916c4bd12b4293add1004320fc323a"
                >
                  {" "}
                  If you are creating a Web Service user
                </Link>
                , please make sure that the user or the user's associated role
                has full permissions to all objects.
              </div>
              <br></br>
              <div className="font-semibold">Company ID</div>
              <div style={{ marginBottom: 16 }} className="mt-2">
                <Input
                  placeholder=""
                  value={companyId}
                  onChange={(e) => setCompanyId(e.target.value)}
                />
              </div>
              <div className="font-semibold">Username</div>
              <div style={{ marginBottom: 16 }} className="mt-2">
                <Input
                  placeholder=""
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="font-semibold">Password</div>
              <div style={{ marginBottom: 16 }} className="mt-2">
                <Input
                  placeholder=""
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                />
              </div>
              <Button
                type="primary"
                block
                disabled={
                  loading ||
                  username === "" ||
                  password === "" ||
                  companyId === ""
                }
                onClick={isSandbox ? handleFakeComplete : handleComplete}
              >
                {loading
                  ? "Connecting..."
                  : `Connect ${isSandbox ? "Test " : ""}Sage Intacct`}
              </Button>
              {shouldShowTextUnderConnect(organization) && (
                <div className="mt-2 text-xs">
                  <TextUnderConnect></TextUnderConnect>
                </div>
              )}
              {error && (
                <Alert
                  className="mt-4"
                  type="error"
                  message={"Error"}
                  description={error}
                  showIcon
                ></Alert>
              )}
            </>
          }
        />
      </Steps>
    </div>
  );
};

export default SageIntacct;
