import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Form,
  Input,
  Steps,
  Typography,
  message,
  Layout,
  Spin,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { UNKNOWN_ERROR_MESSAGE } from "../constants";
import { persistLinkState } from "../hooks/useLinkTracker";
import { captureException } from "../sentry";
import Store from "../stateStore";
import { extractLinkParamsFromState, getBackendUrl, STORES } from "../utils";
import { FiCheckCircle, FiDownload } from "react-icons/fi";
import CopyButton from "../components/CopyButton";
import { useQuery } from "react-query";

const { Link } = Typography;
const DEFAULT_IMAGE_WIDTH = 600;

const { Step } = Steps;

type Props = {
  clientSecret: string;
  onBack: () => any;
  onCancel: () => any;
  onComplete: (public_token: string) => any;
};

function QuickbooksDesktop(props: Props) {
  const { onComplete } = props;

  const [loading, setIsLoading] = React.useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const stateStore = Store.useStore();
  const organizationName = stateStore.get("orgName");
  const orgId = stateStore.get("orgId");
  const [itemId, setItemId] = useState(stateStore.get("itemId"));
  const autoPlatform = stateStore.get("autoPlatform");
  const [form] = Form.useForm();
  const [companyId, setCompanyId] = useState("");
  const [companyIdStatus, setCompanyIdStatus] = useState("...");
  const [stepNumber, setStepNumber] = useState(0);
  const [qwcFileDownloaded, setQwcFileDownloaded] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [companyFilePath, setCompanyFilePath] = useState<string>("");
  const [credentialExists, setCredentialExists] = useState(false);

  const {
    data: isReauthData,
    isLoading: isReauthDataLoading,
    error: reauthDataError,
  } = useQuery(
    ["qbdLinkSetupInfo", itemId],
    () => {
      return axios
        .get(
          `${getBackendUrl()}/quickbooks-desktop/link-setup-info/organizations/${orgId}/items/${itemId}`,
        )
        .then((res) => {
          const { data } = res;
          if (data.companyFilePath) {
            setCompanyFilePath(data.companyFilePath);
          }
          if (data.storeUniqueId) {
            setCredentialExists(true);
            setCompanyId(data.storeUniqueId);
          }
          return data;
        })
        .catch((e) => {
          setError("An unexpected error occurred. Please try again later.");
        });
    },
    {
      enabled: stepNumber === 0 && !!itemId, // if itemId is not provided we assume its a new connection, not a reauth
      staleTime: Infinity, // Data is considered fresh forever
      cacheTime: Infinity, // Data is cached forever
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    },
  );

  const isReauth =
    !isReauthDataLoading && !reauthDataError && isReauthData?.isReauth;

  useQuery(["qbdLinkVerify", itemId], verifyConnection, {
    refetchInterval: 5000, // Poll every 5 seconds
    enabled: stepNumber === 2 && !isReauth, // we dont poll link-verify in reauth case. user must manually click verify connection button
  });

  const companyIdSentString = "Successfully saved Account ID.";
  const isCompanyIdProvided = () => {
    return companyIdStatus === companyIdSentString;
  };

  // Keep updating the status of ProgressSteps based on what the user has done or is doing
  useEffect(() => {
    if (stepNumber === 0 && (isCompanyIdProvided() || isReauthData?.isReauth)) {
      setStepNumber(1);
    }
  }, [companyId, companyIdStatus, isReauthData]);

  const handleAuthError = (e: any) => {
    captureException(e);
    if (e.response?.data?.error_message) {
      setError(e.response.data?.error_message);
    } else {
      setError(UNKNOWN_ERROR_MESSAGE);
    }
  };

  async function verifyConnection() {
    const finishPayload = {
      ...extractLinkParamsFromState(stateStore),
      link_update_id: itemId === "" ? stateStore.get("itemId") : itemId,
      is_reauth: isReauth,
    };
    return axios
      .post(`${getBackendUrl()}/quickbooks-desktop/link-verify`, finishPayload)
      .then((res) => {
        const { data } = res;
        const { is_successful, reason, public_token } = data;
        if (is_successful) {
          onComplete(public_token);
        } else {
          if (isReauth) {
            setError(reason);
          }
        }
      })
      .catch((e) => {
        handleAuthError(e);
      });
  }

  const handleFinish = (values: any) => {
    setIsLoading(true);
    verifyConnection().finally(() => {
      setIsLoading(false);
    });
  };

  const handleGenerateQwc = () => {
    setIsLoading(true);
    persistLinkState(STORES.QUICKBOOKS_DESKTOP, stateStore.getState());
    axios
      .post(`${getBackendUrl()}/quickbooks-desktop/link`, {
        ...extractLinkParamsFromState(stateStore),
        link_update_id: itemId === "" ? stateStore.get("itemId") : itemId,
      })
      .then((res) => {
        const { data } = res;
        const { qwcContent, password } = data;
        const file = new File([qwcContent], "quickbooks.qwc", {
          type: "text/plain",
        });

        function download() {
          const link = document.createElement("a");
          const url = URL.createObjectURL(file);

          link.href = url;
          link.download = file.name;
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
        download();
        setQwcFileDownloaded(true);
        setPassword(password);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Returns the provided company id to the backend.
   */
  const handleSendCompanyId = () => {
    setIsLoading(true);
    setCompanyIdStatus("Wait...");
    axios
      .post(`${getBackendUrl()}/quickbooks-desktop/save-id`, {
        ...extractLinkParamsFromState(stateStore),
        company_id: companyId.trim(),
        company_file_path: companyFilePath.trim(),
      })
      .then(({ data }) => {
        const {
          is_successful: isSuccessful,
          reason: errorMessage,
          item_id,
        } = data;
        if (isSuccessful) {
          setCompanyIdStatus(companyIdSentString);
          setItemId(item_id);
          setError("");
          messageApi.open({
            type: "success",
            content: companyIdSentString,
          });
        } else {
          const errorString =
            errorMessage === "duplicate"
              ? `This account number is already being used with a different connection. Please contact ${stateStore.get(
                  "orgName",
                )} for help.`
              : errorMessage;
          setError(errorString);
          messageApi.open({
            type: "error",
            content: errorString,
          });
        }
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => setIsLoading(false));
  };

  function getStepStatus(
    stepNumber: number,
    currentStepNumber: number,
  ): React.ReactNode {
    if (stepNumber > currentStepNumber) {
      return "To-do";
    } else if (stepNumber === currentStepNumber) {
      return "In-progress";
    } else {
      return <FiCheckCircle className="text-black" />;
    }
  }

  return (
    <Layout className="bg-white w-full">
      <Layout.Content className="overflow-y-auto px-16">
        {contextHolder}
        <div className="w-full flex justify-center">
          <div className="w-full max-w-[800px]">
            <div className="flex justify-center items-center relative">
              <div className="flex items-center justify-between w-full">
                {!autoPlatform && (
                  <Button
                    onClick={props.onBack}
                    icon={<ArrowLeftOutlined />}
                    className="flex items-center"
                  >
                    Back
                  </Button>
                )}
                <div className="flex-grow text-center">
                  <img
                    className="h-20 inline-block"
                    src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/quickbooks-logo.svg"
                    alt="QuickBooks Logo"
                  />
                </div>
                {!autoPlatform && <div className="w-20"></div>}
              </div>
            </div>
            <Steps
              type="navigation"
              progressDot={() => null}
              current={stepNumber}
            >
              <Step
                subTitle={getStepStatus(0, stepNumber)}
                description="1. Input QBD options"
              />
              <Step
                subTitle={getStepStatus(1, stepNumber)}
                description="2. Run the QBD Web Connector"
              />
              <Step
                subTitle={getStepStatus(2, stepNumber)}
                description="3. Start the sync"
              />
            </Steps>
            <div className="mt-10 flex justify-center">
              {stepNumber === 0 && (
                <div className="max-w-[500px]">
                  <p className="mb-4">
                    First, make sure you're on your desktop computer or laptop
                    with Quickbooks Desktop running. You'll need access to your
                    Quickbooks files to complete the setup.
                  </p>
                  <Form
                    requiredMark="optional"
                    form={form}
                    initialValues={{ requiredMarkValue: "optional" }}
                    layout="vertical"
                  >
                    <Form.Item
                      required={true}
                      label="Company name"
                      extra="Make a note of what you enter here — if you need to
                    reconnect, you'll have to enter your company name exactly
                    the same to continue syncing data."
                    >
                      <Input
                        placeholder="Company name"
                        value={companyId}
                        onChange={(e) => {
                          setCompanyId(e.target.value.trim());
                        }}
                        disabled={credentialExists || isCompanyIdProvided()}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Company file path"
                      extra="Please add the company file path if you wish to sync data even when Quickbooks Desktop is not running."
                    >
                      <Input
                        placeholder="Example: C:\path\to\file.qbw"
                        value={companyFilePath}
                        onChange={(e) => setCompanyFilePath(e.target.value)}
                        disabled={credentialExists}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        className="mt-2"
                        style={{ marginRight: "8px" }}
                        disabled={companyId === "" || credentialExists}
                        loading={loading}
                        onClick={handleSendCompanyId}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              )}
              {stepNumber === 1 && (
                <Steps className="" direction="vertical" current={-1}>
                  <Step
                    title="Download .qwc file"
                    description={
                      <div className="my-4 text-black">
                        <Button
                          className="flex gap-2 items-center"
                          type="primary"
                          onClick={handleGenerateQwc}
                          disabled={!isCompanyIdProvided || qwcFileDownloaded}
                        >
                          <FiDownload />
                          QWC File
                        </Button>
                      </div>
                    }
                  />
                  <Step
                    title="Open the QuickBooks Web Connector"
                    description={
                      <div className="my-4 text-black">
                        <div>
                          <p className="mb-2">
                            Click "File" in the Quickbooks Desktop Menu, then
                            select "App Management", then choose "Update Web
                            Services" to open the Web Connector. The Web
                            Connector is typically installed alongside
                            Quickbooks. If you have an issue with this step,{" "}
                            <Link
                              href="https://quickbooks.intuit.com/learn-support/en-us/install-new-products/set-up-quickbooks-web-connector/00/185852"
                              target="_blank"
                            >
                              visit this article
                            </Link>{" "}
                            for more instructions.
                          </p>
                        </div>
                        <img
                          className="ml-8"
                          width={DEFAULT_IMAGE_WIDTH}
                          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/QBD1.png"
                          alt=""
                        />
                      </div>
                    }
                  />
                  <Step
                    title="Import .qwc file"
                    description={
                      <div className="my-4 text-black">
                        <p className="mb-2">
                          Double click the "quickbooks.qwc" file you downloaded
                          to open it in the Web Connector. Click OK.
                        </p>
                        <img
                          className="mb-2 ml-8"
                          width={DEFAULT_IMAGE_WIDTH}
                          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/webconnectorbetterpermission.png"
                          alt=""
                        />
                      </div>
                    }
                  />
                  <Step
                    title="Add required permissions"
                    description={
                      <div className="my-4 text-black">
                        <p className="mb-2">
                          Open Quickbooks and allow the following permissions
                          (this step will be skipped if permissions were
                          previously given):
                        </p>
                        <img
                          className="mb-2 ml-8"
                          width={DEFAULT_IMAGE_WIDTH}
                          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/qbdpermissions.png"
                          alt=""
                        />
                        <img
                          className="mb-2 ml-8"
                          width={DEFAULT_IMAGE_WIDTH}
                          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/qbdconfirmpermissions.png"
                          alt=""
                        />
                      </div>
                    }
                  />
                </Steps>
              )}
              {stepNumber === 2 && (
                <Steps className="" direction="vertical" current={-1}>
                  <Step
                    title="Start syncing"
                    description={
                      <div className="my-4 text-black">
                        <p className="mb-2">
                          In your Web Connector, you will see a new application.
                          Click the checkbox on the left and click "Update
                          Selected".
                        </p>
                        <img
                          className="mb-2 ml-8"
                          width={DEFAULT_IMAGE_WIDTH}
                          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/webconnectorupdateselected.png"
                          alt=""
                        />
                      </div>
                    }
                  />
                  <Step
                    title="Save password"
                    description={
                      <div className="mt-4 text-black">
                        <p className="mb-2">
                          When asked to fill in the password, copy & paste the
                          following password into the box and save the password.
                        </p>
                        <div className="border rounded shadow py-2 px-3 flex items-center justify-between cursor-not-allowed max-w-xl mb-2">
                          <span className="overflow-hidden text-ellipsis whitespace-nowrap flex-grow">
                            {password || " "}
                          </span>
                          <CopyButton
                            textToCopy={password}
                            disabled={!qwcFileDownloaded}
                          />
                        </div>
                        <img
                          className="mb-2 ml-8"
                          width={DEFAULT_IMAGE_WIDTH}
                          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/webconnectorpasswordinputfilled.png"
                          alt=""
                        />
                        <img
                          className="mb-2 ml-8"
                          width={DEFAULT_IMAGE_WIDTH}
                          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/webconnectorpasswordsave.png"
                          alt=""
                        />
                        <p className="mb-2 mt-8">
                          A sync will begin running which you can see the
                          progress in the bottom bar.
                        </p>
                        <img
                          className="mb-2 ml-8"
                          width={DEFAULT_IMAGE_WIDTH}
                          src="https://rutterpublicimages.s3.us-east-2.amazonaws.com/webconnectorsynctriggered.png"
                          alt=""
                        />
                      </div>
                    }
                  />
                  <Step
                    title="Verify connection"
                    description={
                      <>
                        <p className="text-black mt-4">
                          To complete the integration process, we must verify a
                          sync has started.
                        </p>
                        {isReauth ? (
                          <Button
                            disabled={loading || !password}
                            className="mt-2"
                            type="primary"
                            onClick={handleFinish}
                          >
                            Verify Connection
                          </Button>
                        ) : (
                          <div className="text-black flex justify-center">
                            <div className="flex flex-col">
                              <p className="text-lg my-4">
                                Waiting for sync to start.
                              </p>
                              <Spin />
                            </div>
                          </div>
                        )}
                      </>
                    }
                  />
                </Steps>
              )}
            </div>
            {error && (
              <Alert
                className="mb-4"
                type="error"
                message={"Error"}
                description={error}
                showIcon
              ></Alert>
            )}
          </div>
        </div>
      </Layout.Content>
      <Layout.Footer className="bg-white border-t border-gray-300 gap-2 flex justify-end">
        <Button
          onClick={() => setStepNumber(stepNumber - 1)}
          disabled={stepNumber === 0}
        >
          Previous
        </Button>
        <Button
          type="primary"
          onClick={() => setStepNumber(stepNumber + 1)}
          disabled={
            stepNumber === 2 ||
            (stepNumber === 0 && !credentialExists && !isCompanyIdProvided()) ||
            (stepNumber === 1 && !qwcFileDownloaded)
          }
        >
          Next
        </Button>
      </Layout.Footer>
    </Layout>
  );
}

export default QuickbooksDesktop;
