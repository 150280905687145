import * as amplitude from "@amplitude/analytics-browser";
import { Button } from "antd";
import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { useHistory } from "react-router";
import { getBackendUrl } from "../../utils";
import IntuitBankFeedsCommonLayout, {
  LOCAL_STORAGE_ORG_INFO,
  OrgInfo,
} from "./IntuitBankFeedsCommon";

const IntuitBankFeedsConsent: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const { search, pathname } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { consent_challenge } = parsedSearch;
  const [orgInfo] = useState<OrgInfo>(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_ORG_INFO) ?? "{}"),
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const sleep = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleCompleteContinue = async () => {
    amplitude.track("Intuit Bank Feeds: Consent Continue Clicked");
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${getBackendUrl()}/intuit_bank_feeds_connector/auth/consent?challenge=${consent_challenge}`,
        {
          scope: ["accounts", "transactions"],
        },
      );
      amplitude.track(
        "Intuit Bank Feeds: Consent Successful, continuing to success page",
      );

      setIsSuccess(true);
      await sleep(1000);

      window.location.replace(response.data);
    } catch (e: any) {
      amplitude.track("Intuit Bank Feeds: Consent Error");
      setErrorMessage(
        e.response?.data?.error_message ?? "Something went wrong.",
      );
      setIsLoading(false);
    }
  };

  const handleCompleteCancel = () => {
    amplitude.track("Intuit Bank Feeds: Consent Cancelled");
    history.push({
      pathname: "/intuit_bank_feeds_cancelled",
      state: { orgInfo },
    });
  };
  return (
    <IntuitBankFeedsCommonLayout
      companyLogoUrl={orgInfo.companyLogoUrl}
      name={orgInfo.name}
      errorMessage={errorMessage}>
      {isSuccess ? (
        <div className="mt-1 font-thin" style={{ fontWeight: 300 }}>
          Thanks, you've successfully authenticated your {orgInfo.name} account.
          We're now redirecting you back to Intuit to complete the linking
          process.
        </div>
      ) : (
        <>
          <div className="mt-1 font-thin" style={{ fontWeight: 300 }}>
            Intuit will gain access to the following information:
            <ul className="list-disc ml-9 leading-relaxed font-medium">
              <div style={{ marginBottom: "8px" }}></div>{" "}
              {/* Add extra space here */}
              <li>Bank Accounts</li>
              <li>Bank Transactions</li>
            </ul>
          </div>
          <Button
            className="mt-3"
            type="primary"
            block
            disabled={isLoading}
            loading={isLoading}
            onClick={handleCompleteContinue}>
            Continue
          </Button>
          <Button
            className="mt-3"
            type="default"
            block
            onClick={handleCompleteCancel}>
            Cancel
          </Button>
        </>
      )}
    </IntuitBankFeedsCommonLayout>
  );
};

export default IntuitBankFeedsConsent;
